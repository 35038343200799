import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import Header from '../Header'
import Footer from '../Footer'
import Cookies from '../Cookies'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import { withPrefix } from 'gatsby'
import '../../styles/common.sass'
import { setLocale } from 'yup'
import { getCookie } from '../../data/tokenManager'

setLocale({
  mixed: {
    required: 'Champ requis',
  },
  string: {
    email: 'Email invalide',
  },
})

Sentry.init({

  dsn: process.env.GATSBY_SENTRY_DSN,
  ignoreErrors:[
    "Non-Error exception captured",
    "Non-Error promise rejection captured"
  ],
  whitelistUrls: [/https?:\/\/((cdn|www)\.)?artelink\.com/],
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.GATSBY_SENTRY_ENV,
  tracesSampleRate: process.env.GATSBY_SENTRY_TRACE_SAMPLE_RATE
})

const TemplateWrapper = ({ children, transparentHeader = false, isFree = false, withoutFooter = false }) => {
  const { title, description } = useSiteMetadata()
  const [cookiesConsent, setCookiesConsent] = useState(true)

  useEffect(() => {
    setCookiesConsent(typeof window !== 'undefined' ? getCookie('cookiesConsent') : true)
  }, [])

  const handleClickCookies = () => {
    if (typeof window !== 'undefined') document.cookie = 'cookiesConsent=true'
    setCookiesConsent(true)
  }

  return (
    <div>
      <Helmet
        titleTemplate='Artelink - %s'
        defaultTitle='Artelink - la plateforme regroupant les meilleurs experts freelances de l’ingénierie'
      >
        <html lang="fr" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/favicon-16x16.webp`} />
        <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-32x32.webp`} sizes="32x32" />
        <link rel="icon" type="image/png" href={`${withPrefix('/')}img/favicon-16x16.webp`} sizes="16x16" />

        <link rel="mask-icon" href={`${withPrefix('/')}img/safari-pinned-tab.svg`} color="#ff4400" />
        <meta name="theme-color" content="#fff" />

        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Artelink" />
        <meta name="og:locale" content="fr_FR" />

        <script id='googleMaps' src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`} />
      </Helmet>
      {!cookiesConsent && <Cookies onClick={handleClickCookies} />}
      <Header isFree={isFree} transparent={transparentHeader} />
      {children}
      {!withoutFooter && <Footer />}
    </div>
  )
}

export default TemplateWrapper
