// extracted by mini-css-extract-plugin
export var header = "desktop-module--header--YjQ6F";
export var loginConnected = "desktop-module--loginConnected--pSrbX";
export var chevronConnected = "desktop-module--chevronConnected--D6hoJ";
export var transparent = "desktop-module--transparent--eUDBJ";
export var fixed = "desktop-module--fixed--rrNFM";
export var open = "desktop-module--open--r+LmO";
export var loginLinks = "desktop-module--loginLinks--9v6tW";
export var logo = "desktop-module--logo--SkDXV";
export var logoFull = "desktop-module--logoFull--GXntw";
export var conceptLink = "desktop-module--conceptLink--ED7z5";
export var searchButtonContainer = "desktop-module--searchButtonContainer--CaqC7";
export var searchForm = "desktop-module--searchForm--NUShu";
export var searchText = "desktop-module--searchText--uvV5Z";
export var formContainer = "desktop-module--formContainer--6N4li";
export var leftPart = "desktop-module--leftPart--uQhvH";
export var openBackground = "desktop-module--openBackground--6NFnd";
export var arrowContainer = "desktop-module--arrowContainer--vFEFa";
export var leftMenuOpener = "desktop-module--leftMenuOpener--b19Kz";
export var leftMenu = "desktop-module--leftMenu--3U0Jc";
export var headerContent = "desktop-module--headerContent--r9ziL";
export var logoLink = "desktop-module--logoLink--aWe0J";
export var buttonLink = "desktop-module--buttonLink--YlY8K";
export var rightPart = "desktop-module--rightPart--O554j";
export var burger = "desktop-module--burger--soQvw";
export var userPic = "desktop-module--userPic--9Ka-u";