/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'gatsby'
import SearchForm from '../SearchForm'
import Icon from '../Icon'
import LogoFull from '../../img/logo-full.svg'
import Logo from '../../img/logo.svg'
import * as s from './desktop.module.sass'
import useShrinkableHeader from 'react-hooks-use-shrinkable-header'
import ConnectedHeader from './ConnectedHeader'
import UnConnectedHeader from './UnConnectedHeader'

const DesktopHeader = ({ transparent, links, isFree, isConnected, setIsConnected, data }) => {
  const shrinked = useShrinkableHeader(typeof window !== 'undefined' ? window.innerHeight - 200 : 0)
  const [isOpen, setIsOpen] = useState(false)
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false)
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
  const headerRef = useRef(null)

  function openLeftMenu() {
    setIsOpen(true)
    setIsLeftMenuOpen(true)
  }

  function openSearchBar() {
    setIsOpen(true)
    setIsSearchBarOpen(true)
  }

  const closeHeader = useCallback(() => {
    setIsOpen(false)
    setIsLeftMenuOpen(false)
    setIsSearchBarOpen(false)
  }, [setIsOpen, setIsLeftMenuOpen, setIsSearchBarOpen])



  useEffect(() => {
    const escFunction = e => {
      if (e.keyCode === 27) closeHeader() // esc key
    }
    const clickFunction = e => {
      if (headerRef && !headerRef.current.contains(e.target)) { // click outside
        closeHeader()
      }
    }

    document.addEventListener('keydown', escFunction)
    document.addEventListener('mousedown', clickFunction)
    return () => {
      document.removeEventListener('keydown', escFunction)
      document.removeEventListener('mousedown', clickFunction)
    }
  }, [closeHeader])

  useEffect(() => {
    if (!shrinked) closeHeader()
  }, [shrinked, closeHeader])

  const onMouseLeave = () => {
    if (!isSearchBarOpen) closeHeader()
  }

  return (
    <div
      className={[s.header, transparent ? s.transparent : '', shrinked ? s.fixed : '', isOpen ? s.open : ''].join(' ')}
      onMouseLeave={onMouseLeave}
      ref={headerRef}
    >
      <div className={[s.openBackground, isOpen ? s.open : ''].join(' ')} />
      <div className={s.leftPart}>
        <Link to="/" className={s.logoLink}>
          <LogoFull className={s.logoFull} />
          <Logo className={s.logo} />
        </Link>
        <div className={s.conceptLink}>
          <div onMouseEnter={openLeftMenu} className={[s.leftMenuOpener, isLeftMenuOpen ? s.open : ''].join(' ')}>
            <span>Comment ça marche ?</span>
            <span className={s.arrowContainer}>
              <Icon icon="arrowDown" iconSize="xsmall" containerSize="xxsmall" />
            </span>
          </div>
          <div className={[s.leftMenu, isLeftMenuOpen ? s.open : ''].join(' ')}>
            <Link to={links.operation}>
              <Icon icon="rightArrow" iconSize="xxsmall" containerSize="xxsmall" />
              <span>Fonctionnement</span>
            </Link>
            <Link to={links.selectivity}>
              <Icon icon="rightArrow" iconSize="xxsmall" containerSize="xxsmall" />
              <span>Qualification des profils</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={s.searchForm}>
        <div className={s.searchText}>Trouvez l'ingénieur parfait pour votre mission</div>
        <div className={[s.formContainer, isSearchBarOpen ? s.open : ''].join(' ')}>
          <SearchForm onFocusPlaceholder={openSearchBar} closeForm={closeHeader} placeholderMode={!isSearchBarOpen} eventName='' />
        </div>
      </div>
      {
        isConnected ? (
          <ConnectedHeader transparent={transparent} setIsConnected={setIsConnected} data={data} />
        ) : (
          <UnConnectedHeader isFree={isFree} />
        )
      }
    </div>
  )
}

export default DesktopHeader
