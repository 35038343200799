import React from 'react'
import Button from '../Button'
import { Link } from 'gatsby'
import * as s from './desktop.module.sass'

const UnConnectedHeader = ({ isFree }) => {
  const handleEvent = (title) => {
    typeof window !== "undefined" && window.gtag("event", title)
  }

  return (
    <div className={s.rightPart}>
      <span className={s.searchButtonContainer}>
        {isFree ? (
          <a href={`${process.env.GATSBY_APP_URL}/signup?pType=CLIENT`} onClick={() => handleEvent('Website - Header - Company')} className={s.buttonLink}>
            <Button>Je suis une entreprise</Button>
          </a>
        ) : (
          <Link to="/freelance">
            <Button>Je suis expert indépendant</Button>
          </Link>
        )}
      </span>
      <div className={s.loginLinks}>
        <a href={`${process.env.GATSBY_APP_URL}/signin`} onClick={() => handleEvent('Website - Header - SignIn')}>Se connecter</a>
        {isFree ? (
          <a href={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`} onClick={() => handleEvent('Website - Header - SignUp')}>S'inscrire</a>
        ) : (
          <a href={`${process.env.GATSBY_APP_URL}/signup`} onClick={() => handleEvent('Website - Header - SignUp')}>S'inscrire</a>
        )}
      </div>
    </div>
  )
}

export default UnConnectedHeader