// extracted by mini-css-extract-plugin
export var profileContainer = "connectedmobile-module--profileContainer--ku6i-";
export var open = "connectedmobile-module--open--m0Ww6";
export var profile = "connectedmobile-module--profile--756h5";
export var profilePicture = "connectedmobile-module--profilePicture--uxoqy";
export var bottomBorder = "connectedmobile-module--bottomBorder---zp3i";
export var iconContainer = "connectedmobile-module--iconContainer--Z2RoK";
export var section = "connectedmobile-module--section--hQPOF";
export var buttons = "connectedmobile-module--buttons--klyAN";
export var buttonContainer = "connectedmobile-module--buttonContainer--S6HXr";
export var profileSection = "connectedmobile-module--profileSection--0fsFm";
export var icons = "connectedmobile-module--icons--3MhH5";
export var enterBurgerMenu = "connectedmobile-module--enterBurgerMenu--5Kk+t";