import React from 'react'
import { Link } from 'gatsby'
// import { openPopupWidget } from 'react-calendly'

import * as styles from './style.module.sass'

import ContentWrapper from '../ContentWrapper'
import Icon from '../Icon'

import LogoFull from '../../img/logo-full.svg'
import Logo from '../../img/logo.svg'

// const CalendlyButton = ({ url }) => {
//   const onClick = () => openPopupWidget({ url });

//   // typeof window !== "undefined" && window.gtag("event", 'Website - Footer - Meet a mission manager')

//   return <button onClick={onClick} onKeyDown={onClick} className={styles.calendlyButton}>Prendre rendez-vous</button>
// }

const FooterLink = ({ link, children }) => (
  <Link to={link}>
    <Icon icon="rightArrow" iconSize="small" containerSize="small" />
    {children}
  </Link>
)

const ExtFooterLink = ({ link, children, onClick }) => (
  <a href={link} target='_blank' rel='noreferrer' onClick={onClick}>
    <Icon icon="rightArrow" iconSize="small" containerSize="small" />
    {children}
  </a>
)

const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        <ContentWrapper>
          <div className={`${styles.wrapper} columns`}>
            <div className="column is-two-fifths-desktop">
              <div className={styles.logoContainer}>
                <div className={styles.mobileContainer}>
                  <Logo />
                </div>
                <div className={styles.desktopContainer}>
                  <LogoFull />
                </div>
                <h3 className={styles.baseline}>
                  Artelink est la première communauté d'experts indépendants hautement qualifiés dans l'ingénierie
                </h3>
              </div>

              <div className={styles.followUsContainer}>
                <p>Suivez-nous !</p>
                <a className={styles.socialIconsContainer} href="https://www.linkedin.com/company/artelink" target='_blank' rel='noreferrer'>
                  <Icon icon="linkedin" iconSize="large" containerSize="large" />
                </a>
              </div>
            </div>

            <div className="column">
              <div className={`columns ${styles.linksContainer}`}>
                <div className="column">
                  <div className={styles.columnWrapper}>
                    <h4>ENTREPRISE</h4>
                    <FooterLink link="/">Accueil entreprise</FooterLink>
                    <FooterLink link="/fonctionnement">Fonctionnement</FooterLink>
                    <FooterLink link="/selection">Qualification des profils</FooterLink>
                    {/* <div className={styles.footerElemContainer}>
                      <Icon icon="rightArrow" iconSize="small" containerSize="small" />
                      <CalendlyButton url='https://calendly.com/stephanie-artelink/rdv-15-min-artelink' />
                    </div> */}
                    <FooterLink link="/proposition-mission/">Décrivez-nous votre besoin</FooterLink>
                  </div>

                  <div className={styles.columnWrapper}>
                    <h4>INDÉPENDANTS</h4>
                    <FooterLink link="/freelance">Accueil freelance</FooterLink>
                    <FooterLink link="/comment-ca-marche">Fonctionnement</FooterLink>
                    <ExtFooterLink link={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`} onClick={() => typeof window !== "undefined" && window.gtag("event", 'Website - Footer - SignUp')}>S'inscrire</ExtFooterLink>
                    
                  </div>
                </div>

                <div className="column">
                  <div className={styles.columnWrapper}>
                    <h4>ARTELINK</h4>
                    <FooterLink link='/a-propos'>Qui sommes-nous ?</FooterLink>
                    <FooterLink link='/contact'>Nous contacter</FooterLink>
                    <ExtFooterLink link='https://blog.artelink.com'>Lire le blog</ExtFooterLink>
                    <ExtFooterLink link='https://support.artelink.com'>Notre F.A.Q</ExtFooterLink>
                    <ExtFooterLink link='mailto:contact@artelink.com'>Nous rejoindre</ExtFooterLink>
                    <FooterLink link='/conditions-generales'>Nos conditions générales</FooterLink>
                    <FooterLink link='/mentions-legales'>Nos mentions légales</FooterLink>
                    <FooterLink link='/rgpd'>Vos données personnelles</FooterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentWrapper>
      </div>
      <div className={styles.bottomContainer}>
        <ContentWrapper>
          <p>© 2022 Artelink - Tous droits réservés</p>
        </ContentWrapper>
      </div>
    </>
  )
}

export default Footer
