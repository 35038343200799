import React from "react";
import Chevron from '../../img/icons/icon_chevron.svg'
import * as s from './connected.module.sass'
import * as styles from './desktop.module.sass'
import { handleLogout } from "../../data/tokenManager";

const ConnectedHeader = ({ transparent, setIsConnected, data })  => {

  if (!data) return null

  return (
    <div className={[s.rightPart, transparent ? styles.transparent : ''].join(' ')}>
      <div className="navbar-item has-dropdown is-hoverable">
        <div data-testid="hoverableNavBar" className={s.profileDropdown}>
          <div className={`${s.itemProfile} navbar-item`}>
            <div className={styles.loginConnected}>
              <div className={styles.loginConnected}>{data?.firstName}</div>
            </div>
            <div
              className={s.profilePicture}
              style={{
                backgroundImage: `url(${data?.picture?.url})`,
              }}
            />
            <Chevron className={styles.chevronConnected} />
          </div>
          <div className={`${s.dropdown} navbar-dropdown is-boxed`}>
            <a target="_blank" rel="noreferrer" href={process.env.GATSBY_APP_URL} className={`${s.linkItem} link navbar-item`}>
              Mon dashboard
            </a>
            <a target="_blank" rel="noreferrer" href={process.env.GATSBY_SUPPORT_URL} className={`${s.linkItem} link navbar-item`}>
              Aide
            </a>
            <button onClick={() => handleLogout(setIsConnected)} className={`${s.buttonLogout} link navbar-item`}>
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectedHeader