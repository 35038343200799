/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'
import { isLoggedIn, getCookie, removeToken } from '../../data/tokenManager'
import useFetchUserData from '../../hooks/useFetchUserData'

const linksRef = {
  freelance: {
    operation: '/comment-ca-marche',
    selectivity: '/selection',
  },
  client: {
    operation: '/fonctionnement',
    selectivity: '/selection',
  },
}

const Header = ({ isFree, ...props }) => {
  const links = isFree ? linksRef.freelance : linksRef.client
  const value = getCookie('token')
  const [isConnected, setIsConnected] = useState(isLoggedIn())
  const data = useFetchUserData()

  useEffect(() => {
    setIsConnected(isLoggedIn())
  }, [isConnected]);

  if (!value)
    removeToken()

  return (
    <>
      <MobileHeader links={links} isFree={isFree} isConnected={isConnected} setIsConnected={setIsConnected} data={data} />
      <DesktopHeader {...props} links={links} isFree={isFree} isConnected={isConnected} setIsConnected={setIsConnected} data={data} />
    </>
  )
}

export default Header
