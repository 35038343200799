import React from "react"
import Button from '../Button'
import { Link } from 'gatsby'
import Icon from '../Icon'
import * as s from './mobile.module.sass'

const UnconnectedMobileHeader = ({ isFree, closeBurgerMenu }) => {
  return (
    <div>
      <div className={s.engineerLink}>
        {
          !isFree && (
            <Link to='/freelance' onClick={closeBurgerMenu}>
              Je suis expert indépendant
              <div className={s.iconContainer}>
                <Icon icon="rightArrow" iconSize="large" />
              </div>
            </Link>
          )
        }
        {
          isFree && (
            <Link to='/' onClick={closeBurgerMenu}>
              Je suis une entreprise
              <div className={s.iconContainer}>
                <Icon icon="rightArrow" iconSize="large" />
              </div>
            </Link>
          )
        }
      </div>
      <div className={s.buttons}>
        <a className={s.buttonContainer} href={`${process.env.GATSBY_APP_URL}/signin`}>
          <Button type="full">Se connecter</Button>
        </a>
        <a className={s.buttonContainer} href={`${process.env.GATSBY_APP_URL}/signup`}>
          <Button type="white">S'inscrire</Button>
        </a>
      </div>
    </div>
  )
}

export default UnconnectedMobileHeader