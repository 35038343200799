/* eslint jsx-a11y/click-events-have-key-events: 0 jsx-a11y/no-static-element-interactions: 0 */
import React from 'react'
import iconsList from '../../data/iconsList.js'
import * as s from './style.module.sass'

const sizes = {
  xxsmall: 10,
  xsmall: 12,
  small: 16,
  normal: 24,
  medium: 32,
  large: 48,
  xlarge: 70
}

const Icon = ({ 
  icon,
  containerSize = "normal",
  iconSize = "normal",
  style = {},
  clickHandler = () => null
}) => {
  const IconSVGComponent = iconsList[icon]

  return (
    <div
      onClick={clickHandler}
      className={[s.iconContainer, s[containerSize]].join(' ')}
      style={style}
    >
      <div className={[s.icon, s[iconSize]].join(' ')}>
        <IconSVGComponent width={sizes[iconSize]} height={sizes[iconSize]} />
      </div>
    </div>
  )
}

export default Icon
