import React from 'react'

import * as styles from './style.module.sass'

const Cookies = ({ onClick }) => (
  <div className={styles.cookiesWrapper}>
    <div className={styles.cookiesContainer}>
      <p className={styles.text}>Artelink utilise des cookies pour une meilleure expérience. En poursuivant votre navigation, vous acceptez leur utilisation.</p>
      <button className={styles.button} onClick={() => onClick()}>
        Accepter
      </button>
    </div>
  </div>
)

export default Cookies
