import React, { useState, useEffect } from 'react'

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import * as s from './style.module.sass'

const AutoCompleteInput = ({ location, setLocation }) => {
  const [loaded, setLoaded] = useState(false)
  const [searchOptions] = useState(null)
  const [address, setAddress] = useState((location && location.label) || '')

  useEffect(() => {
    if (loaded || typeof window === 'undefined' || !window.document) return

    const intervalId = setInterval(() => {
      const script = document.getElementById('googleMaps')
      if (!!script) setLoaded(true)
    }, 100)
    return () => { clearInterval(intervalId) }
  }, [loaded])

  const handleChange = newAddress => {
    setAddress(newAddress)
    setLocation({
      label: newAddress,
      coordinates: null,
    })
  }

  const handleSelect = newAddress => {
    setAddress(newAddress)
    geocodeByAddress(newAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setLocation({
          label: newAddress,
          coordinates: `${latLng.lat},${latLng.lng}`,
        })
      })
  }

  if (!loaded) return null

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      highlightFirstSuggestion
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps({
          placeholder: 'Ex : Paris, France'
        })
        return (
          <div style={{ flex: 1 }}>
            <input
              {...inputProps}
              onChange={e => {
                inputProps.onChange(e)
              }}
              value={address}
            />
            <div
              className={`dropdown is-active ${s.dropdownContainer}`}
            >
              {(suggestions.length !== 0 || loading) && (
                <div className={`dropdown-menu is-active`}>
                  <ul className={`dropdown-content ${s.placesSuggestions}`}>
                    {suggestions.map((suggestion, index) => {
                      return (
                        <li
                          {...getSuggestionItemProps(suggestion, {
                            className: `dropdown-item ${suggestion.active ? s.active : ''}`,
                          })}
                          key={index}
                        >
                          <span>{suggestion.description}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default AutoCompleteInput