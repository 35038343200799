import { useEffect, useState } from 'react'
import API from "../data/axios";
import { getLocalStorageToken } from "../data/tokenManager";

const useFetchUserData = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    let token = getLocalStorageToken()

    const getUserData = async () => {
      try {
        await API.get('/users/light-me', {
          headers: {
            Authorization: `bearer ${(token.accessToken)}`,
          }
        })
          .then((res) => {
            setData(res.data)
          })
      } catch (err) { }
    }
    getUserData()
  }, []);
  return data
}

export default useFetchUserData