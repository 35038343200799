import Bolt from '../img/icons/bolt.svg'
import Location from '../img/icons/location.svg'
import Search from '../img/icons/search.svg'
import Person from '../img/icons/person.svg'
import Burger from '../img/icons/burger.svg'
import PicturePlaceholder from '../img/icons/picture-placeholder.svg'
import ArrowUp from '../img/icons/arrow-up.svg'
import RightArrow from '../img/icons/right-arrow.svg'
import ArrowDown from '../img/icons/arrow-down.svg'
import Bulb from '../img/icons/bulb.svg'
import Cross from '../img/icons/cross.svg'
import Twitter from '../img/icons/twitter.svg'
import Facebook from '../img/icons/facebook.svg'
import Linkedin from '../img/icons/linkedin.svg'
import Error from '../img/icons/error.svg'
import Valid from '../img/icons/valid.svg'
import Envelope from '../img/icons/envelope.svg'
import Diplome from '../img/icons/diplome.svg'
import CV from '../img/icons/cv.svg'
import References from '../img/icons/references.svg'
import Books from '../img/icons/books.svg'
import Diamon from '../img/icons/diamon.svg'

const iconsList = {
  bolt: Bolt,
  location: Location,
  search: Search,
  person: Person,
  burger: Burger,
  picturePlaceholder: PicturePlaceholder,
  arrowUp: ArrowUp,
  rightArrow: RightArrow,
  arrowDown: ArrowDown,
  bulb: Bulb,
  cross: Cross,
  twitter: Twitter,
  facebook: Facebook,
  linkedin: Linkedin,
  error: Error,
  valid: Valid,
  envelope: Envelope,
  diplome: Diplome,
  cv: CV,
  references: References,
  books: Books,
  diamon: Diamon
}

export default iconsList
