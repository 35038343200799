// extracted by mini-css-extract-plugin
export var formContainer = "style-module--formContainer--5cuAH";
export var form = "style-module--form--finF1";
export var focused = "style-module--focused--jGVf5";
export var inputIcon = "style-module--inputIcon--Y0esO";
export var placeholderInputContainer = "style-module--placeholderInputContainer--eTLLz";
export var inputContainer = "style-module--inputContainer--pjfqM";
export var shadow = "style-module--shadow--QDlkZ";
export var buttonContainer = "style-module--buttonContainer---dXTV";
export var bigger = "style-module--bigger--pkppW";
export var placesSuggestions = "style-module--placesSuggestions--kPVCE";
export var active = "style-module--active--Wy2iq";
export var dropdownContainer = "style-module--dropdownContainer--9BObj";