import React from 'react'
import useMobileMenu from 'react-hooks-use-mobile-menu'
import { Link } from 'gatsby'
import SearchForm from '../SearchForm'
import Icon from '../Icon'
import Logo from '../../img/logo-full.svg'
import * as s from './mobile.module.sass'
import ConnectedMobileHeader from './ConnectedMobileHeader'
import UnConnectedMobileHeader from './UnConnectedMobileHeader'

const MobileHeader = ({ links, isFree, isConnected, setIsConnected, data }) => {
  const [isBurgerMenuOpen, openBurgerMenu, closeBurgerMenu] = useMobileMenu()
  const [isSearchMenuOpen, openSearchMenu, closeSearchMenu] = useMobileMenu()

  const ClosingMenuLink = ({ to, children }) => (
    <Link to={to} onClick={closeBurgerMenu}>{children}</Link>
  )

  return (
    <div className={s.header}>
      {/* FIXED TOPBAR */}
      <div className={s.logoContainer}>
        <Link to="/"><Logo /></Link>
      </div>
      <div className={s.actions}>
        <Icon clickHandler={openSearchMenu} icon="search" iconSize="medium" containerSize="medium" />
        <Icon clickHandler={openBurgerMenu} icon="burger" iconSize="medium" containerSize="medium" />
      </div>

      {/* BURGER MENU */}
      <div className={[s.burgerMenu, isBurgerMenuOpen ? s.open : ''].join(' ')}>
        <div className={s.closeButtonContainer}>
          <Icon clickHandler={closeBurgerMenu} icon="cross" iconSize="medium" containerSize="medium" />
        </div>
        <div className={s.burgerMenuContent}>
          <div className={s.upperPart}>
            <div className={s.upperTitle}>Comment ça marche ?</div>
            <ClosingMenuLink to={links.operation}>
              <div className={s.linkContainer}>
                Comprendre
                <br />
                notre fonctionnement
                <div className={s.iconContainer}>
                  <Icon icon="rightArrow" iconSize="large" containerSize="medium" />
                </div>
              </div>
            </ClosingMenuLink>
            <ClosingMenuLink to={links.selectivity}>
              <div className={s.linkContainer}>
                Découvrir
                <br />
                notre sélectivité
                <div className={s.iconContainer}>
                  <Icon icon="rightArrow" iconSize="large" containerSize="medium" />
                </div>
              </div>
            </ClosingMenuLink>
            {!isConnected ? (<div className={s.bottomBorder} /> ): ''}
          </div>
          {
            isConnected ? (
              <ConnectedMobileHeader isBurgerMenuOpen={isBurgerMenuOpen} setIsConnected={setIsConnected} data={data} />
            ) : (
              <UnConnectedMobileHeader closeBurgerMenu={closeBurgerMenu} isFree={isFree} />
            )
          }
        </div>
      </div>
      {/* SEARCH MENU */}
      <div className={[s.searchMenu, isSearchMenuOpen ? s.open : ''].join(' ')}>
        <div className={s.closeButtonContainer}>
          <Icon clickHandler={closeSearchMenu} icon="cross" iconSize="medium" containerSize="medium" />
        </div>
        <div className={s.searchMenuContent}>
          <div className={s.formTitle}>
            Trouvez l'ingénieur parfait
            <br />
            pour votre mission
          </div>
          <div className={s.formContainer}>
            <SearchForm />
          </div>
        </div>
      </div>
    </div >
  )
}

export default MobileHeader
