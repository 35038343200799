import React from "react";
import * as s from './connectedmobile.module.sass'
import Icon from '../Icon'
import Button from "../Button"
import { handleLogout } from "../../data/tokenManager";

const ConnectedMobileHeader = ({ isBurgerMenuOpen, setIsConnected, data }) => {

  if (!data) return null

  return (
    <div className={[s.profileContainer, isBurgerMenuOpen ? s.open : ''].join(' ')}>
      <div className={[s.profile, s.profileSection].join(' ')}>
        <div className={s.profilePicture}
          style={{
            backgroundImage: `url(${data?.picture?.url})`,
          }}
        />
        <div className={s.bottomBorder} />
      </div>
      <a target="_blank" rel="noreferrer" href={process.env.GATSBY_APP_URL} className={s.section}>
        Mon dashboard
        <div className={s.iconContainer}>
          <Icon icon="rightArrow" iconSize="large" />
        </div>
      </a>
      <a target="_blank" rel="noreferrer" href={process.env.GATSBY_SUPPORT_URL} className={s.section} >
        Aide
        <div className={s.iconContainer}>
          <Icon icon="rightArrow" iconSize="large" />
        </div>
      </a>
      <div className={s.buttons}>
        <div className={s.buttonContainer}>
          <Button type="white" onClick={() => handleLogout(setIsConnected)} >
            Déconnexion
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConnectedMobileHeader
