import React, { useState, useEffect, useRef } from 'react'

import * as s from './style.module.sass'

import AutoCompleteInput from './AutoCompleteInput'
import Button from '../Button'
import Icon from '../Icon'

const SearchForm = ({ placeholderMode = false, onFocusPlaceholder = () => null, eventName = null }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [location, setLocation] = useState('')
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const firstRenderRef = useRef(true)

  // Effect hides focus on input when going back to placeholder mode
  // even if blurCapture event did not fire.
  // Also, it will focus the input when setting the placeholder mode
  // to false, except at first render
  useEffect(() => {
    if (placeholderMode) {
      setIsFocused(false)
    } else if (firstRenderRef.current === false) {
      inputRef.current.focus()
    }

    if (firstRenderRef.current === true) {
      firstRenderRef.current = false
    }
  }, [placeholderMode])

  useEffect(() => {
    if (!!eventName && typeof window !== 'undefined') {
      const Search = () => inputRef.current.focus()
      window.addEventListener(eventName, Search)

      return () => window.removeEventListener(eventName, Search)
    }
  }, [eventName])

  const onSubmit = (e) => {
    e.preventDefault()

    // typeof window !== "undefined" && window.gtag("event", 'Website - Header - Search', {
    //   query: inputRef.current.value
    // })

    if (!!inputRef.current.value) {
      let url = `${process.env.GATSBY_APP_URL}/search?&query=${inputRef.current.value}`
      if (!!location?.coordinates && !!location.label)
        url += `&coordinates=${location.coordinates}&location=${encodeURIComponent(location.label)}`

      window.location.href = url
    }
  }

  return (
    <div className={s.formContainer} ref={formRef}>
      <div className={s.shadow} />
      <form
        onSubmit={onSubmit}
        className={[s.form, isFocused ? s.focused : ''].join(' ')}
        onFocusCapture={() => setIsFocused(true)}
        onBlurCapture={() => setIsFocused(false)}
      >
        {placeholderMode === false && (
          <>
            <div className={s.inputContainer}>
              <Icon icon="bolt" />
              <input ref={inputRef} type="text" name="jobType" placeholder='Essayez "Bim Manager"' />
            </div>
            <div className={s.inputContainer}>
              <Icon icon="location" />
              <AutoCompleteInput location={location} setLocation={setLocation} />
            </div>
          </>
        )}
        {placeholderMode && (
          <div className={s.placeholderInputContainer}>
            <input
              onFocus={onFocusPlaceholder}
              type="text"
              name="placeholder"
              placeholder="Commencez votre recherche"
            />
          </div>
        )}
        <div className={[s.buttonContainer, placeholderMode ? s.bigger : ''].join(' ')}>
        <Button
          icon="search"
          iconSize={placeholderMode ? 'small' : 'normal'}
          containerSize={placeholderMode ? 'small' : 'normal'}
          width="100%"
          height="100%"
        >
          Rechercher
        </Button>
        </div>
      </form>
    </div>
  )
}

export default SearchForm
